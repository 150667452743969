/* 移动端显示更多按钮 */
.toggle-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.toggle-btn-box .btn-more {
    display: none;
}

@media screen and (max-width:992px) {
    .toggle-btn-box.max-w-900 .btn-more {
        display: inline-block;
    }
}

@media screen and (max-width:425px) {
    .toggle-btn-box.max-w-425 .btn-more {
        display: inline-block;
    }
}

/* 拟态框 */
.modal-view {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.modal-view .modal-view-box {
    z-index: 2;
    overflow: scroll;
    padding: 5em;
    max-height: 100vh;
    width: 80%;
    background-color: #fff;
}

.modal-view .modal-view-box .modal-btn {
    display: flex;
    justify-content: flex-end;
}

.modal-view .dark-layer {
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: var(--up-dark);
    opacity: 0.3;
}

/* 打开拟态框禁止页面滚动 */
html.disable-scroll {
    overflow: hidden;
    height: 100%;
}


.scrollbar::-webkit-scrollbar {
    width: 20px;
}

.scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    border-radius: 20px;
    background-color: #d6dee1;
    background-clip: content-box;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}



.page_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.page_wrap .paginator {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    font-size: 20px;
    line-height: 1.8;
}

.page_wrap .paginator .page-start {
    display: none;
}

.page_wrap .paginator .page-end {
    display: none;
}

.page_wrap .paginator .page-item,
.page_wrap .paginator .page-prev,
.page_wrap .paginator .page-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    color: var(--up-dark);
    font-weight: bold;
}

.page_wrap .paginator .page-this {
    background-color: var(--up-orange);
    color: #fff;
}

.page_wrap .paginator .page-prev .fas,
.page_wrap .paginator .page-next .fas {
    padding-top: 2px;
    font-size: 22px;
}


.truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* 需要设置 width */
}

.title-box {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.subTitle-text {
    margin-bottom: .2em;
    color: var(--up-gray-dark);
    font-size: 18px;
}

.title-text {
    margin-bottom: .5em;
    color: var(--up-orange);
    font-weight: bold;
    font-size: 44px;
    line-height: 1;
}

.description-text {
    margin-bottom: 1em;
    font-size: 24px;
}


.display-none {
    display: none;
}

.display-block {
    display: block;
}

.full-banner {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--up-light);
}

/* 面包屑导航 */
.breadcrumb {
    padding: 2em 0;
    background-color: #fff;
}

.breadcrumb>a {
    color: #fff;
}

.btn-more {
    display: inline-block;
    padding: 12px 15px;
    width: auto;
    border: 2px solid;
    cursor: pointer;
}

.btn-more.up-orange {
    border-color: var(--up-orange);
}

.btn-more.hover-light-orange p,
.btn-more.hover-light-orange a,
.btn-more.hover-light-orange i{
    position: relative;
    color: var(--up-dark);
}

.btn-more.up-orange p,
.btn-more.up-orange a,
.btn-more.up-orange i {
    color: var(--up-dark);
}

.btn-more.up-orange:hover p,
.btn-more.up-orange:hover a,
.btn-more.up-orange:hover i {
    color: #fff;
}

.btn-more.up-orange:hover {
    background-color: var(--up-orange) !important;
}

.btn-more.up-bold {
    font-weight: bold;
}

.arrow {
    display: inline-block;
    padding: 3px;
    border: solid black;
    border-width: 0 3px 3px 0;
}

.arrow.up-orange {
    border-color: var(--up-orange);
}

.btn-more:hover .arrow.up-orange {
    border-color: #fff;
}

.arrow-right {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.arrow-left {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

.wrap {
    position: relative;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    padding: 0;
}

.wrap::after {
    display: table;
    clear: both;
    content: "";
}

/*返回顶部*/
.fix-nav {
    position: fixed;
    bottom: 60px;
    left: 100%;
    z-index: 999;
    display: none;
    margin-left: -60px;
    width: 100%;
}

.fix-nav .fix-nav-wrap {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}

.fix-nav .i-totop {
    display: block;
    margin: 0 auto;
    width: 28px;
}

@media (min-width: 768px) {
    .wrap {
        width: 83%;
        /* max-width: 1600px; */
    }
}

/* @media screen and (min-width:1200px) {
    .wrap {
        width: 1180px;
    }
} */


@media screen and (max-width:1300px) {
    .subTitle-text {
        font-size: 16px;
    }

    .title-text {
        font-size: 36px;
    }

    .description-text {
        font-size: 20px
    }
}

@media screen and (max-width:1024px) {
    .modal-view .modal-view-box {
        width: 100%;
    }
}

@media screen and (min-width:992px) and (max-width:1199px) {
    .wrap {
        width: 960px;
    }
}

@media screen and (min-width:320px) and (max-width:767px) {
    .wrap {
        max-width: 640px;
    }
}

@media screen and (max-width:650px) {
    .wrap {
        margin-right: 10px;
        margin-left: 10px;
    }

}

@media screen and (max-width:425px) {
    .btn-more {
        padding: 6px 7.5px;
    }

    .btn-more p {
        font-size: 12px;
    }
}